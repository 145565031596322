require('./views');
require('./bootstrap');

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueTelInput from 'vue-tel-input'
import vuetify from './plugins/vuetify';
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts)

import './assets/sass/app.scss';

Vue.use(VueTelInput, {
  autoFormat: true,
  defaultCountry: 'KE',
  onlyCountries: ['KE'],
  mode: 'international',
  dropdownOptions: {
    width: '320px',
    required: true,
    showDialCodeInList: false
  },
  inputOptions: {
    name: 'contact_person.phone',
    placeholder: 'Contact person\'s number'
  }
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
