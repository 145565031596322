<template>
  <v-card flat color="transparent">
    <v-card-title>
      <div>
        <h1 class="title font-weight-bold grey--text text--darken-1">
          {{ title }}
        </h1>
        <app-crumbs :crumbs="breadcrumbs" />
      </div>

      <v-spacer></v-spacer>

      <slot name="actions">
        <v-btn
          v-if="showCallToAction"
          color="primary"
          class="ttn body-2 rounded-lg"
          @click="$emit('call-to-action')"
        >
          {{ callToActionText }}
        </v-btn>
      </slot>

      <slot name="analyticActions">
        <div v-if="showCallToActionForAnalytics">
          <v-icon
            class="ma-3"
            color="primary"
            large
            @click="$emit('call-to-action-pdf')"
          >
            mdi-file-pdf-box
          </v-icon>

          <v-icon
            size="32"
            color="primary"
            @click="$emit('call-to-action-csv')"
          >
            mdi-file-delimited
          </v-icon>
        </div>
      </slot>
    </v-card-title>

    <v-card-text>
      <slot name="content" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
    },

    callToActionText: {
      type: String,
    },

    callToActionPermission: {
      type: String,
    },

    hasAnalyticsButton: {
      type: Boolean,
    },
  },

  data() {
    return {
      menuItems: ['PDF (.pdf)', 'Comma-Separated Values (.csv)'],
    }
  },

  computed: {
    showCallToAction() {
      return (
        Boolean(this.callToActionText) && vault.can(this.callToActionPermission)
      )
    },

    showCallToActionForAnalytics() {
      return (
        Boolean(this.hasAnalyticsButton) &&
        vault.can(this.callToActionPermission)
      )
    },
  },
}
</script>
