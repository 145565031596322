import { render, staticRenderFns } from "./BaseDialogForm.vue?vue&type=template&id=b6433c70"
import script from "./BaseDialogForm.vue?vue&type=script&lang=js"
export * from "./BaseDialogForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports