export default {
  state: {
    role: null,
    permissionSearch: '',
    permissionType: 'resourceful',
  },

  getters: {
    getRole: ({ role }) => role,
    getPermissionType: ({ permissionType }) => permissionType,
    getPermissionSearch: ({ permissionSearch }) => permissionSearch,
  },

  mutations: {
    setRole (state, data) {
      state.role = data
    },

    setPermissionType (state, data) {
      state.permissionType = data
    },

    setPermissionSearch (state, data) {
      state.permissionSearch = data
    }
  },

  actions: {
    setRole ({ commit }, data) {
      commit('setRole', data)
    },

    setPermissionType ({ commit }, data) {
      commit('setPermissionType', data)
    },

    setPermissionSearch ({ commit }, data) {
      commit('setPermissionSearch', data.target.value)
    },
  },
}