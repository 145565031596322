const serviceRoutes = {
  iam: [
    { name: "roles", url: "roles" },
    { name: "users", url: "users" },
    { name: "circles", url: "circles" },
    { name: "clients", url: "clients" },
    { name: "permissions", url: "permissions" },
    { name: "partnerships", url: "partnerships" },
    { name: "groups", url: "partnerships" },
    { name: "groupMembers", url: "partnerships" },
    { name: "search", url: "search" },
    { name: "searchablePartnerships", url: "partnerships" },
  ],

  insurance: [
    { name: "referenceUnits", url: "reference-units" },
    { name: "productVersions", url: "product-versions" },
    { name: "priceConfigurations", url: "price-configurations" },
    { name: "referenceUnitCoefficients", url: "reference-unit-coefficients" },
    { name: "orders", url: "orders" },
    { name: "chargeTypes", url: "charge-types" },
    { name: "offerorSubsidies", url: "offeror-subsidies" },
  ],

  nchi: [
    { name: "jurisdictions", url: "jurisdictions" },
    { name: "counties", url: "jurisdictions/?level=1" },
    { name: "subCounties", url: "jurisdictions/?level=2" },
    { name: "wards", url: "jurisdictions/?level=3" },
    { name: "countries", url: "countries" },
    { name: "boundaries", url: "boundaries" },
  ],

  silo: [
    { name: "farmResources", url: "resources" },
    { name: "varieties", url: "varieties" },
    { name: "valueChains", url: "value-chains" },
    { name: "valueChainOutputs", url: "value-chain-outputs" },
    { name: "farms", url: "farms" },
    { name: "farm", url: "farms/:farm_uid" },
    { name: "metrics", url: "metrics" },
    { name: "farmOutputs", url: "farm-outputs" },
    { name: "categories", url: "categories" },
  ],

  retail: [
    { name: "distributionPoints", url: "distribution-points" },
    { name: "loadingCharges", url: "loading-charges" },
    { name: "loadings", url: "loadings" },
    { name: "invoices", url: "invoices" },
    { name: "mpesaCallback", url: "mpesa-callback" },
    { name: "payments", url: "payments" },
    { name: "prices", url: "prices" },
    { name: "products", url: "products" },
    { name: "retailOrders", url: "orders" },
    { name: "stkPush", url: "stk-push" },
    { name: "invoices", url: "invoices" },
  ],
  ussd: [{ name: "warranties", url: "warranties" }],
  payments: [{ name: "configurations", url: "hela/configure-paybill" }],
}

let routes = []
for (const service in serviceRoutes) {
  routes = routes.concat(
    serviceRoutes[service].map(({ name, url }) => ({
      name,
      url: `${service}/${url}`,
    }))
  )
}

export default routes
