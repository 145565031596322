<template>
  <div 
    class="text-center pt-2" 
    v-if="meta"
  >
    <v-pagination 
      v-model="currentPage" 
      :length="lastPage"
      :total-visible="totalVisible"
    ></v-pagination>

    <p class="body-2 mt-4" v-if="meta.total !== 0">
      Showing {{ meta.from }} - {{ meta.to }} of {{ meta.total }}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    "meta",
  ],

  data () {
    return {
      lastPage: null,
      currentPage: null,
      totalVisible: 5,
    }
  },

  watch: {
    currentPage (page, old) {
      if (old != null) {
        this.$emit('pageChanged', page)
      }
    }
  },

  mounted () {
    this.currentPage = this.meta.current_page
    this.lastPage = this.meta.last_page
  }
}
</script>