import Vault from "@/libs/core/Vault";
import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
  {
    path: "/auth",
    component: () => import("@/layouts/AuthLayout.vue"),
    beforeEnter: (to, from, next) => {
      const { token, user } = Vault.peek();
      if (!token) {
        if (user && to.name != "login") {
          next("/auth/login");
        } else {
          next();
        }
      } else {
        next("/");
      }
    },
    children: [
      {
        path: "register",
        name: "register",
        component: () => import("@/views/RegisterPage.vue"),
      },

      {
        path: "login",
        name: "login",
        component: () => import("@/views/LoginPage.vue"),
      },
    ],
  },

  {
    path: "/accounts",
    name: "account.selection",
    component: () => import("@/views/AccountSelectionView.vue"),
    beforeEnter: (to, from, next) => {
      if (!Vault.take("client")) {
        next("/auth/login");
      } else {
        const suppliers = Object.values(Vault.take("suppliers"));
        if (suppliers.length > 1 && !Vault.take("account_selected")) {
          next();
        } else {
          next({ name: "dashboard" });
        }
      }
    },
  },

  {
    path: "",
    component: () => import("@/layouts/AppLayout.vue"),
    beforeEnter: (to, from, next) => {
      if (!Vault.take("client")) {
        next("/auth/login");
      } else {
        const suppliers = Object.values(Vault.take("suppliers"));

        if (
          suppliers.length == 1 ||
          from.name == "account.selection" ||
          Vault.take("account_selected")
        ) {
          next();
        } else {
          next({ name: "account.selection" });
        }
      }
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardPage.vue"),
      },

      {
        path: "iam/partnerships",
        name: "iam.partnerships.index",
        component: () => import("@/views/iam/PartnershipView.vue"),
      },

      {
        path: "iam/groups",
        name: "iam.groups.index",
        component: () => import("@/views/iam/GroupView.vue"),
      },

      {
        path: "iam/groups/:groupUid",
        name: "silo.orders.store",
        component: () => import("@/views/iam/GroupMemberView.vue"),
      },

      {
        path: "insurance/product-versions",
        name: "insurance.product-versions.index",
        component: () => import("@/views/insurance/ProductVersionPage.vue"),
      },

      {
        path: "insurance/price-configurations",
        name: "insurance.price-configurations",
        component: () => import("@/views/insurance/PriceConfigurationPage.vue"),
      },

      {
        path: "retail/orders",
        name: "retail.orders.index",
        component: () => import("@/views/retail/OrderView.vue"),
      },

      {
        path: "retail/payments",
        name: "retail.payments.index",
        component: () => import("@/views/retail/PaymentView.vue"),
      },

      {
        path: "retail/distribution-points",
        name: "retail.distribution-points.index",
        component: () => import("@/views/retail/DistributionPointPage.vue"),
      },

      {
        path: "retail/prices",
        name: "retail.prices.index",
        component: () => import("@/views/retail/PricePage.vue"),
      },

      {
        path: "retail/loadings",
        name: "retail.loadings.index",
        component: () => import("@/views/retail/LoadingPage.vue"),
      },

      {
        path: "retail/loading-charges",
        name: "retail.loading-charges.index",
        component: () => import("@/views/retail/LoadingChargePage.vue"),
      },

      {
        path: "retail/leaderboard",
        name: "retail.leaderboard.index",
        component: () => import("@/views/retail/GroupPage.vue"),
      },

      {
        path: "retail/leaderboard_statistics/:customerUid",
        name: "retail.invoices.index",
        component: () => import("@/components/retail/LeaderboardStatistics.vue"),
      },
      {
        path: "retail/invoices",
        name: "retail.invoices.index",
        component: () => import("@/views/retail/InvoiceView.vue"),
      },

      {
        path: "silo/farms/:clientUId",
        name: "silo.farms.index",
        component: () => import("@/views/silo/FarmPage.vue"),
      },

      {
        path: "silo/farms/:clientUId/:farmUid",
        name: "silo.farm-outputs.index",
        component: () => import("@/views/silo/FarmOutputPage.vue"),
      },

      {
        path: "iam/roles",
        name: "iam.roles.index",
        component: () => import("@/views/iam/RolePage.vue"),
      },

      {
        path: "iam/roles/:roleUid",
        name: "iam.roles.show",
        component: () => import("@/views/iam/PermissionPage.vue"),
      },

      {
        path: "iam/users",
        name: "iam.users.index",
        component: () => import("@/views/iam/UserPage.vue"),
      },
      {
        path: "ussd/warranties",
        name: "ussd.warranties.index",
        component: () => import("@/views/ussd/WarrantyView.vue"),
      },
    ],
  },

  { path: "*", component: () => import("../views/errors/404.vue") },
];

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
