import { fields } from '@/libs/retail/Loading'

export default {
  state: {
    loadingFields: fields,
    loadingErrors: null,
    pointChargePayload: [],
    distribution_point_uid: null,
    order_items: [],
    customer_uid: null,
  },

  getters: {
    getLoadingFields: ({ loadingFields }) => loadingFields,
    getLoadingErrors: ({ loadingErrors }) => loadingErrors,
    getPointChargePayload: ({ pointChargePayload }) => pointChargePayload,
    getDistributionPointUid: (state) => state.distribution_point_uid,
    getOrderItems: (state) => state.order_items,
    getCustomerUid: (state) => state.customer_uid,
  },

  mutations: {
    setLoadingFields (state, data) {
      state.loadingFields = {
        ...state.loadingFields,
        ...data,
      }
    },

    setLoadingErrors (state, data) {
      state.loadingErrors = data
    },

    setPointChargePayload (state, data) {
      state.pointChargePayload = data
    },

    setDistributionPointUid (state, data) {
      state.distribution_point_uid = data
    },  

    setOrderItems (state, data) {
      state.order_items = data
    },

    setCustomerUid (state, data) {
      state.customer_uid = data
    },
  },

  actions: {
    setLoadingFields ({ commit }, data) {
      commit('setLoadingFields', data)
    },

    setLoadingErrors ({ commit }, data) {
      commit('setLoadingErrors', data)
    },

    setPointChargePayload ({ commit }, data) {
      commit('setPointChargePayload', data)
    },

    setDistributionPointUid ({ commit }, data) {
      commit('setDistributionPointUid', data)
    },  

    setOrderItems ({ commit }, data) {
      commit('setOrderItems', data)
    },

    setCustomerUid ({ commit }, data) {
      commit('setCustomerUid', data)
    },
  },
}