import Vue from "vue";

/** Core components - used application wide */
Vue.component('app-flash', require('@/components/core/Flash.vue').default);
Vue.component('app-crumbs', require('@/components/core/Crumbs.vue').default);
Vue.component('app-pagination', require('@/components/core/Pagination.vue').default);

/** Pages and layouts */
Vue.component('base-page', require('@/components/BasePage.vue').default);
Vue.component('base-dialog-form', require('@/components/BaseDialogForm.vue').default);

