export default {
  state: {
    priceConfigurationPayload: {
      loading_fees: [],
      subsidies: [],
    },
  },

  getters: {
    getPriceConfigurationPayload: (state) => {
      return state.priceConfigurationPayload
    },
  },

  mutations: {
    setPriceConfigurationPayload (state, data) {
      state.priceConfigurationPayload = {
        ...state.priceConfigurationPayload,
        ...data
      }
    }
  },

  actions: {
    setPriceConfigurationPayload ({ commit }, data) {
      commit('setPriceConfigurationPayload', data)
    }
  },
}