import Vue from 'vue';
import Vuex from 'vuex';
import apix from './apix';

import acl from './modules/acl'
import order from './modules/order'
import retail from './modules/retail';
import pricing from './modules/pricing'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...apix.state,
  },

  getters: {
    ...apix.getters,
  },

  actions: {
    ...apix.actions,
  },

  mutations: {
    ...apix.mutations,
  },

  modules: {
    pricing: {
      namespaced: true,
      ...pricing,
    },

    acl: {
      namespaced: true,
      ...acl,
    },

    retail: {
      namespaced: true,
      ...retail,
    },

    order: {
      namespaced: true,
      ...order,
    },
  },
});

export default store