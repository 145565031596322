import Form from '@/libs/core/Form'
import Vault from '@/libs/core/Vault'

export default class Base {
  constructor(fields) {
    this.setFields(fields)
    this.form = new Form(fields)
    this.client = Vault.take('client')
  }

  setFields(fields) {
    for (let field in fields) {
      this[field] = fields[field]
    }
  }

  getFields(items = []) {
    let data = Object.assign({}, this)
    delete data.form
    delete data.client

    if (items.length > 0) {
      for (let field in data) {
        if (items.indexOf(field) === -1) {
          delete data[field]
        }
      }
    }
    return data
  }
}