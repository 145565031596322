import Base from '@/libs/core/Base'

export const fields = {
  category: 'retail',
  name: null,
  description: '',
  apply_on: 'product',
  apply_as: 'flat',
  apply_once: false,
  is_pre_charged: false,
  meta: {
    categories: [
      { text: 'Insurance', value: 'insurance', },
      { text: 'Retail', value: 'retail', },
    ],
  }
}

export default class Loading extends Base {
  constructor () {
    super(fields);
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        const response = await this.form.submit('post', 'retail/loadings', data)
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}
