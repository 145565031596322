<template>
  <v-app> 
    <app-flash details=""></app-flash>
    
    <v-container class="app--full-height" fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
export default {}
</script>