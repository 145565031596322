<template>
  <v-dialog
    persistent
    v-model="dialog"
    :width="width || 400"
  >
    <v-card>
      <v-card-title>
        <h2 class="body-1 font-weight-bold">
          {{ title }}
        </h2>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          color="red"
          @click="$emit('close')"
        >
          <v-icon small color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>

      <v-card-text :class="squeeze ? 'pa-0': ''">
        <slot name="content"></slot>
      </v-card-text>

      <v-card-actions 
        class="px-6 pb-7"
        v-if="actionText"
      >
        <v-btn
          large
          block
          type="submit"
          color="primary"
          :dark="!loading"
          :loading="loading"
          :disabled="loading"
          class="caption font-weight-bold rounded-lg"
          @click="$emit('submit')"
        >
          {{ actionText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    squeeze: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
    },

    title: {
      required: true,
      type: String,
    },

    dialog: {
      required: true,
      type: Boolean,
    },

    actionText: {
      type: String,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  }
}
</script>