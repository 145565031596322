import Vue from 'vue'
import Vault from '@/libs/core/Vault'

window.events = new Vue()

window.flash = (details) => {
  window.events.$emit('flash', details)
}

window.vault = Vault
