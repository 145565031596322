import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3bacb6',
        secondary: '#34495e',
        accent: '#e67e22',
      },
    },
  },
});
