export default {
  state: {
    productVersion: null,

    orderPayload: {
      jurisdiction_uid: null,
      product_version_uid: null,
      order_items: [],
    },

    order: null,
  },

  getters: {
    getProductVersion: (state) => state.productVersion,
    getOrderPayload: (state) => state.orderPayload,
    getOrder: (state) => state.order,
  },

  mutations: {
    setProductVersion (state, data) {
      state.productVersion = data
    },

    setOrderPayload (state, data) {
      state.orderPayload = {
        ...state.orderPayload,
        ...data,
      }
    },

    setOrder (state, data) {
      state.order = data
    },
  },

  actions: {
    setProductVersion ({ commit, dispatch }, data) {
      dispatch('setOrderPayload', { 
        product_version_uid: data.product_version_uid
      })
      commit('setProductVersion', data)
    },

    setOrderPayload ({ commit }, data) {
      commit('setOrderPayload', data)
    },

    setOrder ({ commit }, data) {
      commit('setOrder', data)
    },
  },
}